import './TopBar.css'
import { Link } from 'react-router-dom';
import React from 'react';

function TopBar() {
    return(
            <div className="top-bar">
                <div className="top-bar-left">
                    <Link to={'/'}>
                    <h2 className='header-font header-purple'>Penguin Pantry</h2>
                    </Link>
                </div>

            </div>
    )
}

export default TopBar;