import React from 'react';
import ReactDOM from 'react-dom/client';
import {
  createBrowserRouter,
  createRoutesFromElements,
  RouterProvider,
  Route,
  Navigate,
} from "react-router-dom";
import './index.css';
import App, { recipesLoader } from './pages/App/App';
import ErrorPage from './pages/ErrorPage/ErrorPage';
import Landing from './pages/Landing/Landing';
import RecipeView, { recipeLoader, recipeViewAction } from './pages/RecipeView/RecipeView';
import Layout from './pages/Layout/Layout';
import ProtectedLayout from './pages/Layout/ProtectedLayout';
import PostRecipe from './pages/PostRecipe/PostRecipe';
import Login, {loginLoader} from './pages/Login/Login';


const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route element={<Layout />} errorElement={<ErrorPage />}>
        {/* Landing Page Route */}
        <Route
          index
          element={<Landing />} 
        />
      </Route>
      <Route
        path='login'
        element={<Layout />}
        errorElement={<ErrorPage />}  
      >
        <Route
          index
          element={<Login />}
          loader={loginLoader}
          errorElement={<ErrorPage />}
        />

      </Route>
      {/* Protected Layout for /recipe */}
      <Route path="recipe" element={<ProtectedLayout />} errorElement={<ErrorPage />}>
        {/* Recipes Route */}
        <Route
          index
          element={<App />}
          loader={recipesLoader}
          errorElement={<ErrorPage />}
        />
        
        {/* Recipe View Route */}
        <Route
          path=":id"
          element={<RecipeView />}
          loader={recipeLoader} 
          action={recipeViewAction} 
          errorElement={<ErrorPage />}
        />
        
        {/* Post Recipe Route */}
        <Route
          path="post"
          element={<PostRecipe />}
          action={recipeViewAction} 
          errorElement={<ErrorPage />}
        />
      </Route>

      {/* Catch-All Error Route */}
      <Route path="*" element={<Navigate to="/" />} errorElement={<ErrorPage />} />
    </>
  )
);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>  
      <RouterProvider router={router} />
  </React.StrictMode>
);