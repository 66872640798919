import React from 'react';
import './Modal.css';

export interface ModalProps {
  open: boolean;
  handleOpen: () => void;
  children?: React.ReactNode;
}

function Modal({open, handleOpen, children}: ModalProps) {
  
  return (
                <>
      {open && (
        <div className="modal" onClick={(e) => {
            if (e.target !== e.currentTarget) return
            handleOpen()
            }}>
          <div className="modal-content">
            {children}
          </div>
        </div>
      )}
      </>

  );
}

export default Modal;
