import './PostRecipe.css';
import { redirect } from 'react-router-dom';
import RecipeViewer from '../../components/RecipeViewer/RecipeViewer';
import React from 'react';


function PostRecipe() {

  return (
   <RecipeViewer recipe={{id: "-1", name: "", ingredients: [], instructions: [], userid: "", public: true}} isPost={true} />     
  )
}

export const postAction = async ({ request }: { request: Request }) => {
  const form = await request.formData();
  const data = Object.fromEntries(form);

  const ingredients: string[] = Object.entries(data).reduce((ingredients, [key, value]) => {
    const [ingredientsKey] = key.split("-");
    if (ingredientsKey === "ingredient") {
      ingredients.push(value as string);
    }
    return ingredients;
  }, [] as string[]);

  const instructions: string[] = Object.entries(data).reduce((instructions, [key, value]) => {
    const [instructionsKey] = key.split("-");
    if (instructionsKey === "instruction") {
      instructions.push(value as string);
    }
    return instructions;
  }, [] as string[]);

  const submit = {
    name: data.name,
    ingredients,
    instructions,
    public: true,
  };

  
  
  try {
    const accessToken = localStorage.getItem('recipes-token');

    await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/v0/recipe`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify(submit),
    });
  } catch (error) {
    if (error instanceof Response) {
      throw error;
    } else {
      throw new Response('Error fetching recipes', { status: 500 });
    }
  }

  return redirect('/recipe')
}


export default PostRecipe;
