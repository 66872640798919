// TextAreaWithResize.tsx
import React from 'react';

interface TextAreaWithResizeProps {
  value: string;
  name: string;
  autoFocus: boolean;
  disabled: boolean;
  onChange: (name: string, value: string) => void;
}

const TextAreaWithResize: React.FC<TextAreaWithResizeProps> = ({
  value,
  name,
  autoFocus,
  disabled,
  onChange,

}) => {

  const textAreaResize = (el: EventTarget) => {
    const textarea = el as HTMLTextAreaElement;
    textarea.style.height = '10px';
    textarea.style.height = textarea.scrollHeight + 'px';
  }

  React.useEffect(() => {
    const textarea = document.querySelector(`textarea[name="${name}"]`);
    if (textarea) {
      textAreaResize(textarea);
    }
  }, [disabled]);

  return (
    <>
      <textarea
        value={value}
        name={name}
        required={true}
        autoFocus={autoFocus}
        onChange={(e) => onChange(e.target.name, e.target.value)}
        onInput={(e) => textAreaResize(e.target)}
        className='instruction-step edit'
        disabled={disabled}
      />
    </>
  );
};

export default TextAreaWithResize;