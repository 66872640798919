import './RecipeCard.css'
import { Recipe } from '../../recipe';
import { BsValentine} from 'react-icons/bs';
import { Link } from 'react-router-dom';
import React from 'react';



type RecipeCardProps = {
    recipe: Recipe,
}

function RecipeCard({recipe}: RecipeCardProps) {

    return(
            <div className="recipe-card">
                <Link to={`${recipe.id}`}>
                <div className='recipe-card-top'>
                    <BsValentine size={20} color='#BB86FC'/>
                </div>
                <div className="recipe-card-bottom">
                    <div className='card-name'>{recipe.name}</div>
                    {/* <div className='card-icons'>
                        <BsHeart className='card-icon' size={20} color='white'/>
                        <BsLink45Deg className='card-icon' size={20} color='white'/>
                        
                    </div> */}
                </div>
                </Link>
            </div>
    )
}

export default RecipeCard;