import './App.css'
import RecipeCard from '../../components/RecipeCard/RecipeCard';
import { Recipe } from '../../recipe';import { useLoaderData, useNavigation, Link } from 'react-router-dom';
import React from 'react';


function App() {
  const recipes = useLoaderData() as Recipe[];
  const navigation = useNavigation();

  return (
    <>
      <div className='App'>
        <div className='recipe-container'>
          {recipes && recipes.length >= 1 ? recipes && recipes.map((recipe: Recipe, index: number) => (<RecipeCard key={'recipe ' + index} recipe={recipe} />))
          : navigation.state !== 'loading' ? <div>
            <div className='empty-recipe-card'>
              <h2>No recipes to display</h2>
              <Link to="/recipe/post">
                <button className='top-bar-button'>Add Recipe</button>
              </Link>
            </div>
          </div>: <></>}
        </div>
        
      </div>
    </>
  );
}

export const recipesLoader = async () => {
  try {
    const accessToken = localStorage.getItem('recipes-token');
    const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/v0/recipe`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      credentials: 'include',
    });

    if (response.ok) {
      const recipes = await response.json();
      return recipes;
    } else {
      throw new Response('Error fetching recipes', { status: response.status });
    }
  } catch (error) {
    if (error instanceof Response) {
      throw error;
    } else {
      throw new Response('Error fetching recipes', { status: 500 });
    }
  }
}

export default App;
