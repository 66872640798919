import { isRouteErrorResponse, useRouteError } from "react-router-dom";
import './ErrorPage.css'
import GoogleOAuth from "../../components/GoogleOAuth/GoogleOAuth";
import React from 'react';

export default function ErrorPage() {
  const error = useRouteError();
  if (isRouteErrorResponse(error)) {
    if (error.status === 401) {
      //redirect to login page
      return (<div className="error-page">
        <h1>Uh Oh. Your session has expired!</h1>
        <GoogleOAuth />
      </div>)

    }
    else if (error.status === 404) {
      // ...
    }

    return (
      <>
      {/* <TopBar /> */}
      <div className="error-page">
        <h1>Oops! {error.status}</h1>
        <p>{error.statusText}</p>
        {error.data?.message && (
          <p>
            <i>{error.data.message}</i>
          </p>
        )}
      </div>
      </>
    );
  } else if (error instanceof Error) {
    return (
      <>
      {/* <TopBar /> */}
      <div className="error-page">
        <h1>Oops! Unexpected Error</h1>
        <p>Something went wrong.</p>
        <p>
          <i>{error.message}</i>
        </p>
      </div>
      </>
    );
  } else {
    return (
    <>
      {/* <TopBar /> */}
      <div className="error-page">
        <h1>404 Error</h1>
        <p>Page not found</p>
      </div>;
    </>
  )
  }
}