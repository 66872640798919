import { BsBoxArrowRight } from "react-icons/bs";
import React from 'react';


const LogoutButton = () => {

  const handleLogout = async () => {
    // go to "/"
    const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/v0/user/logout`, {
      method: 'GET',
      credentials: 'include'
    });
    if (response.ok) {
      window.location.href = '/';
    } else {
      console.error("Error logging out");
    }
  }

  return (
    <>
    <button className='top-bar-button logout-button' onClick={() => handleLogout()}>
      Log Out
    </button>
    <BsBoxArrowRight className="top-bar-icon logout-button" size={30} color={'white'} onClick={() => handleLogout()} />
    </>
  );
};

export default LogoutButton;