import Modal from '../Modal/Modal';
import Context, { RecipeViewerContextType } from '../../Context'
import React from 'react';

function DeleteRecipe() {
    const {currDeleteItem, currDeleteItemFunc, deleteItemOpen, handleDeleteItem} = React.useContext(Context) as RecipeViewerContextType

    return (
            
                <Modal open={deleteItemOpen} handleOpen={handleDeleteItem}>
                    <div className='modal-container'>
                        <h2>Delete {currDeleteItem}?</h2>
                        <div className='modal-buttons'>
                            <button className='submit-button' onClick={handleDeleteItem}>Cancel</button>
                            <button className='submit-button' onClick={() => {currDeleteItemFunc(); handleDeleteItem();}}>Yes, Delete {currDeleteItem}</button>
                        </div>
                        
                    </div>
                    
                </Modal>
    );
    }

export default DeleteRecipe;
