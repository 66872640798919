import './RecipeView.css'
import RecipeViewer from '../../components/RecipeViewer/RecipeViewer';
import { Recipe } from '../../recipe';import { useLoaderData, redirect } from 'react-router-dom';
import React from 'react';


function RecipeView() {
    const recipe = useLoaderData() as Recipe;

    return (
      <RecipeViewer recipe={recipe} isPost={false} />
    )
  }

export const recipeLoader = async ({params}: any) => {
    try {
      const accessToken = localStorage.getItem('recipes-token');
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/v0/recipe/${params.id}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        credentials: 'include',
      });
      if (response.ok) {
        const recipe = await response.json();
        return recipe;
      } else {
        throw new Response('Error fetching recipe', { status: response.status });
      }
    } catch (error) {
      if (error instanceof Response) {
        throw error;
      } else {
        throw new Response('Error fetching recipes', { status: 500 });
      }
    }
}

export const recipeViewAction = async ({ request, params }: { request: Request, params: any }) => {
  try {
    const formData = await request.formData();
    const id = params.id;
    if (request.method === 'PUT') {
      const name = formData.get('name') as string;
      const ingredients = formData.get('ingredients') as string;
      const instructions = formData.get('instructions') as string;
      const recipeId = params.id;
      const shared = formData.get('public') === 'true';
      // Create the updated recipe object
      const recipe = {
        name: name,
        ingredients: JSON.parse(ingredients),
        instructions: JSON.parse(instructions),
        public: shared
      };

      try {
        const accessToken = localStorage.getItem('recipes-token');
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/v0/recipe/${recipeId}`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`,
          },
          credentials: 'include',
          body: JSON.stringify(recipe), // Send the updated recipe object
        });

        if (response.ok) {
          // Recipe was successfully updated in the API

        } else {
          console.error('Failed to update the recipe');
        }
      } catch (error) {
        console.error('Error updating the recipe:', error);
      }

      return { name, ingredients, instructions };
    } else if (request.method === 'DELETE') {
      const accessToken = localStorage.getItem('recipes-token');
      try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/v0/recipe/${id}`, {
            method: 'DELETE',
            headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}`,
            },
            credentials: 'include',
        });
    
        if (response.ok) {
            // Recipe was successfully submitted to the API

        } else {
            console.error('Failed to delete recipe');
        }
        //return response data
        } catch (error) {
        console.error('Error submitting recipe:', error);
        }
        return redirect('/recipe');
    } else if (request.method === 'POST') {
      const name = formData.get('name') as string;
      const ingredients = formData.get('ingredients') as string;
      const instructions = formData.get('instructions') as string;
      const shared = formData.get('public') === 'true';
      // Create the recipe object
      const submit = {
        name: name,
        ingredients: JSON.parse(ingredients),
        instructions: JSON.parse(instructions),
        public: shared
      };

      try {
        const accessToken = localStorage.getItem('recipes-token');
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/v0/recipe`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`,
          },
          credentials: 'include',
          body: JSON.stringify(submit),
        });
        if (response.ok) {
          const newRecipe: Recipe = await response.json();
          // Recipe was successfully submitted to the API
          return redirect(`/recipe/${newRecipe.id}`)
        } else {
          return redirect('/recipe')
        }
      } catch (error) {
        console.error('Error submitting recipe:', error);
      }

    }
  } catch (error) {
    console.error('Error processing recipe view action:', error);
  }
}

export default RecipeView;