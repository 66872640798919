import React from 'react';

export type RecipeViewerContextType = {
    trashOpen: boolean,
    handleTrashOpen: () => void,
    deleteItemOpen: boolean,
    handleDeleteItem: () => void,
    currDeleteItem: string,
    currDeleteItemFunc: () => void,
}

const Context = React.createContext<RecipeViewerContextType | null>(null);

export default Context;