import './TopBar.css'
import { Link, useLocation } from 'react-router-dom';
import { BsPlusLg } from "react-icons/bs";
import LogoutButton from '../LogoutButton/LogoutButton';
import React from 'react';

function ProtectedTopBar() {
    const location = useLocation()
    return(
            <div className="top-bar">
                <div className="top-bar-left">
                    <Link to={location.pathname !== '/' ? '/recipe' : '/'}>
                    <h2 className='header-font header-purple'>Penguin Pantry</h2>
                    </Link>
                </div>
                {<div className="top-bar-right">
                    {location.pathname !== '/recipe/post' && location.pathname !== '/' && <Link to="/recipe/post">
                        <button className='top-bar-button'>Add Recipe</button>
                        <BsPlusLg className="top-bar-icon" size={30} color={'white'} />
                    </Link>}  
                    <LogoutButton />    
                    {/* <Profile /> */}
                </div>}
    
                
            </div>
    )
}

export default ProtectedTopBar;