import './Landing.css';
import GoogleOAuth from '../../components/GoogleOAuth/GoogleOAuth';
import React from 'react';
import { DotLottieReact } from '@lottiefiles/dotlottie-react';



function Landing() {

      const coralDarkBlue = require ('./CoralDarkBlue.lottie')

    return (
        <div className='landing-page'>
                <div className='landing-card'>
                    <div className='header-stack'>
                        <h1>
                            <span className='header-purple'>
                                Collect
                            </span>,&nbsp;
                            <span className='header-purple'>
                                Create
                            </span>,&nbsp;
                            <span className='header-purple'>
                                Cook
                            </span> – All in One Place</h1>
                        <p>We make it easy to collect your favorite recipes, customize them to fit your taste, and keep everything organized so you can focus on creating delicious meals.</p>
                        <div className='landing-buttons'>
                            <GoogleOAuth />
                        </div>
                    </div>
                    <div className='lottie-animation'>
                        <DotLottieReact
                            src={coralDarkBlue}
                            loop
                            autoplay
                        />
                    </div>
                    
                    
            </div>
            <div className='landing-container-small'>
                <div className='landing-card-small'>
                    <h2 className='header-purple'>Collect</h2>
                    <p>Save your favorite recipes from anywhere—whether they’re family treasures, online finds, or your own culinary creations.</p>
                </div>
                <div className='landing-card-small'>
                    <h2 className='header-purple'>Create</h2>
                    <p>Tailor recipes to suit your taste with custom substitutions and adjustments for every occasion.</p>
                </div>
                <div className='landing-card-small'>
                    <h2 className='header-purple'>Cook</h2>
                    <p>Follow your personalized recipes with ease and keep your kitchen time stress-free and enjoyable.</p>
                </div>
            </div>
        </div>
    );
};

export default Landing;
