import { FormEvent } from 'react';
import './RecipeViewer.css'
import Context, { RecipeViewerContextType } from '../../Context'
import DeleteRecipe from '../../components/DeleteRecipe/DeleteRecipe';
import DeleteItem from '../../components/DeleteItem/DeleteItem';
import TextAreaWithResize from '../../components/TextAreaWithResize/TextAreaWithResize';
import { Recipe } from '../../recipe';import { BsFillTrash3Fill, BsPencilSquare, BsPlusLg, BsXLg } from 'react-icons/bs';
import { useFetcher, useLocation } from 'react-router-dom';
import { useState, useEffect } from 'react';
import React from 'react';


interface RecipeViewProps {
    isPost?: boolean;
    recipe: Recipe;
}

function RecipeViewer({isPost, recipe}: RecipeViewProps) {
    const location = useLocation();
    const fetcher = useFetcher();

    const [trashOpen, setTrashOpen] = useState<boolean>(false);
    const [editOpen, setEditOpen] = useState<boolean>(false);
    const [deleteItemOpen, setDeleteItemOpen] = useState<boolean>(false);
    const [currDeleteItem, setCurrDeleteItem] = useState<string>('');
    const [currDeleteItemFunc, setCurrDeleteItemFunc] = useState<() => () => void>(() => () => {});
    const [editedIngredients, setEditedIngredients] = useState<string[]>(recipe.ingredients);
    const [editedInstructions, setEditedInstructions] = useState<string[]>(recipe.instructions);
    const [editedRecipeName, setEditedRecipeName] = useState<string>(recipe.name);
    const [isSaveEnabled, setIsSaveEnabled] = useState<boolean>(false);
  
    const handleDeleteItem = () => {
      setDeleteItemOpen(!deleteItemOpen);
    }

    const handleEditOpen = (isSaving: boolean) => {
      setEditOpen(!editOpen);
      if (!isSaving) {
        setIsSaveEnabled(false);
        setEditedIngredients(recipe.ingredients);
        setEditedInstructions(recipe.instructions);
        setEditedRecipeName(recipe.name);
      }
    }
  
    const handleTrashOpen = () => {
      setTrashOpen(!trashOpen);
    }

    const beforeUnloadHandler = (event: any) => {
      event.preventDefault();
      event.returnValue = ''; // For legacy browser support
    };
    
    function handleIsSaveEnabled() {
      const isIngredientsDifferent = JSON.stringify(editedIngredients) !== JSON.stringify(recipe.ingredients);
      const isInstructionsDifferent = JSON.stringify(editedInstructions) !== JSON.stringify(recipe.instructions);
    
      const isAnyDifferent = isIngredientsDifferent || isInstructionsDifferent || editedRecipeName !== recipe.name;
    
      setIsSaveEnabled(isAnyDifferent);
    }
  
    const handleRecipeEdit = (name: string, value: string) => {
      if (name === 'recipeName') {
        setEditedRecipeName(value);
      } else if (name.startsWith('ingredient-')) {
        const index = parseInt(name.split('-')[1]);
        const updatedIngredients = [...editedIngredients];
        updatedIngredients[index] = value;
        setEditedIngredients(updatedIngredients);
      } else if (name.startsWith('instruction-')) {
        const index = parseInt(name.split('-')[1]);
        const updatedInstructions = [...editedInstructions];
        updatedInstructions[index] = value;
        setEditedInstructions(updatedInstructions);
      }
    }

    const addRecipeEdit = (name: string) => {
      if (name === 'ingredient') {
        const updatedIngredients = [...editedIngredients];
        updatedIngredients.push('');
        setEditedIngredients(updatedIngredients);
      } else if (name === 'instruction') {
        const updatedInstructions = [...editedInstructions];
        updatedInstructions.push('');
        setEditedInstructions(updatedInstructions);
      }
    }

    const deleteRecipeEdit = (name: string, index: number) => {
      if (name === 'ingredient') {
        const updatedIngredients = [...editedIngredients];
        updatedIngredients.splice(index, 1);
        setEditedIngredients(updatedIngredients);
      } else if (name === 'instruction') {
        const updatedInstructions = [...editedInstructions];
        updatedInstructions.splice(index, 1);
        setEditedInstructions(updatedInstructions);
      }
    }

    useEffect(() => {
      handleIsSaveEnabled();

      if (isSaveEnabled) {
        // Add event listener when there are unsaved changes
        window.addEventListener('beforeunload', beforeUnloadHandler);
      } else {
        // Remove event listener when changes are saved or no edits have been made
        window.removeEventListener('beforeunload', beforeUnloadHandler);
      }

      // Remove the listener when the component unmounts
      return () => {
        window.removeEventListener('beforeunload', beforeUnloadHandler);
      };
    }, [editedIngredients, editedInstructions, editedRecipeName, isSaveEnabled]);

    return (
        <Context.Provider value={{ trashOpen, handleTrashOpen, deleteItemOpen, handleDeleteItem, currDeleteItem, currDeleteItemFunc } as RecipeViewerContextType}>
          <div className="recipe-container">
            <form className='recipe-view' onSubmit={(event: FormEvent<HTMLFormElement>) => {event.preventDefault()}}>
              <div className="card-header header-font">
                <div>
                  <input
                    className='header-left'
                    type="text"
                    value={editedRecipeName}
                    name="recipeName"
                    placeholder='Recipe Name'
                    required={true}
                    onChange={(e) => handleRecipeEdit(e.target.name, e.target.value)}
                    disabled={!(editOpen || isPost)}
                  /> 
                </div> 
    
                <div className="header-right">
                  {!isPost ? <>{editOpen ? 
                  <>
                  <button type="submit" className="submit-button" disabled={!isSaveEnabled} onClick={async () => {
                    const form:HTMLFormElement | null = document.querySelector('form.recipe-view');
                    if (form && form.checkValidity()) {
                        fetcher.submit({
                            "name": editedRecipeName,
                            "ingredients": JSON.stringify(editedIngredients),
                            "instructions": JSON.stringify(editedInstructions),
                            "userid": recipe.userid,
                            "public": recipe.public,
                          }, {
                            method: 'PUT',
                            action: location.pathname,
                        });
                        handleEditOpen(true);
                    }
                    }}
                    >Save</button>
                  <button className="submit-button" onClick={() => handleEditOpen(false)}>Cancel</button>
                  </> : <div className="icon-bg" title="Edit" onClick={() => handleEditOpen(false)}>
                    <BsPencilSquare size={20} />
                  </div>}
                  
    
                  <div className="icon-bg" title="Delete" onClick={() => handleTrashOpen()}>
                    <BsFillTrash3Fill size={20} />
                  </div>
  
                  {/* //TODO: Implement visibility */}
                  {/* <div className="icon-bg" title="Visibility" onClick={() => handlePublicOpen(recipe)}>
                    {recipe.public ? <BsEyeSlashFill size={20} /> : <BsEyeFill size={20} />}
                  </div> */}
                  </> : 
                  <button 
                    type="submit" 
                    className="submit-button" 
                    onClick={() => {
                      const form:HTMLFormElement | null = document.querySelector('form.recipe-view');
                      if (form && form.checkValidity()) {
                          fetcher.submit({
                              "name": editedRecipeName,
                              "ingredients": JSON.stringify(editedIngredients),
                              "instructions": JSON.stringify(editedInstructions),
                              "userid": recipe.userid,
                              "public": recipe.public,
                            }, {
                              method: 'POST',
                              action: location.pathname,
                          });
                          handleEditOpen(true);
                      }
                      }}
                    disabled={editedIngredients.length === 0 || editedInstructions.length === 0}
                    >
                      Submit</button>}
                </div>
              </div>
              <div className="divider"></div>
              <div className="card-body">
                <div className='ingredients'>
                  <div className="chip">
                    <div className="header-secondary-font chip-text">Ingredients</div>
                  </div>
                  
                    <>
                      {editedIngredients.length > 0 && <ul className={'ingredients-list regular-font'}>
                        {editedIngredients.map((ingredient, index) => (
                          <li key={`ingredient-${index}`}>
                            <div className='map-item'>
                            <input
                              type="text"
                              value={ingredient}
                              name={`ingredient-${index}`}
                              required={true}
                              onChange={(e) => handleRecipeEdit(e.target.name, e.target.value)}
                              autoFocus = {isPost || editOpen}
                              disabled={!(editOpen || isPost)}
                            />
                            <BsXLg size={20} className='delete-field' onClick={() => {
                              //If ingredient is empty, delete it, else popup modal
                              if (editedIngredients[index] === "") {
                                deleteRecipeEdit('ingredient', index)
                              } else {
                                handleDeleteItem();
                                setCurrDeleteItem("Ingredient");
                                setCurrDeleteItemFunc(() => () => {deleteRecipeEdit('ingredient', index)});
                              }
                              }
                            }
                            ></BsXLg>
                            </div>
                          </li>))}
                        
                      </ul>}
                      
                    </>
                  
                  {(editOpen || isPost) && 
                  <>
                  <BsPlusLg size={20} className="add-field" onClick={() => addRecipeEdit('ingredient')}></BsPlusLg>
                  </>}
                </div>
              
                <div className='instructions'>
                {editedInstructions.length === 0 && <div className="chip">
                    <div className="header-secondary-font chip-text">Instructions</div>
                  </div>
                  }
                  {editedInstructions.map((instruction, index) => (
                    <div key={`instruction-${index}`} className="instruction-step">
                      <div className="chip">
                        <div className="header-secondary-font chip-text">Step {index + 1}</div>
                      </div>
                      <div className='map-item'>
                      <TextAreaWithResize
                        value={editedInstructions[index]}
                        name={`instruction-${index}`}
                        autoFocus={isPost || editOpen}
                        disabled={!(editOpen || isPost)}
                        onChange={handleRecipeEdit}
                      />
                        <BsXLg size={20} className='delete-field' onClick={() => {
                              //If instruction is empty, delete it, else popup modal
                              if (editedInstructions[index] === "") {
                                deleteRecipeEdit('instruction', index)
                              } else {
                                handleDeleteItem();
                                setCurrDeleteItem("Instruction");
                                setCurrDeleteItemFunc(() => () => {deleteRecipeEdit('instruction', index)});
                              }
                              }
                            }></BsXLg>
                      </div> 
                    </div>
                  ))}
                  {(editOpen || isPost) && 
                  <>
                  <BsPlusLg size={20} className="add-field" onClick={() => addRecipeEdit('instruction')}></BsPlusLg>
                  </>}
                </div>
              </div>
            </form>
          </div>
          <DeleteRecipe />
          <DeleteItem />
        </Context.Provider>
      );
}

export default RecipeViewer;