import Modal from '../Modal/Modal';
import './DeleteRecipe.css';
import Context, { RecipeViewerContextType } from '../../Context';
import { useFetcher, useLocation } from 'react-router-dom';
import React from 'react';


function DeleteRecipe() {
    const location = useLocation()
    const fetcher = useFetcher()
    const {trashOpen, handleTrashOpen} = React.useContext(Context) as RecipeViewerContextType

    return (
                <Modal open={trashOpen} handleOpen={handleTrashOpen}>
                    <div className='modal-container'>
                        <h2>Delete Recipe?</h2>
                        <div className='modal-buttons'>
                            <button className='submit-button' onClick={handleTrashOpen}>Cancel</button>
                            <button className='submit-button' onClick={() => {
                                fetcher.submit(null, {
                                    method: 'DELETE',
                                    action: location.pathname,
                                }); 
                                handleTrashOpen()}}
                        >Yes, Delete</button>
                        </div>
                        
                    </div>
                    
                </Modal>
        );
}

export default DeleteRecipe;
