import ProtectedTopBar from '../../components/TopBars/ProtectedTopBar';
import { Outlet } from 'react-router-dom';
import React from 'react';

export default function ProtectedLayout() {

  return (
    <>
      <ProtectedTopBar />
      <Outlet />
    </>
  );
}
