import Spinner from "../../components/Spinner/Spinner";
import { useLoaderData, useNavigation, Navigate } from 'react-router-dom';
import React from 'react';


export default function Login() {
    const redirect = useLoaderData() as string;
    const navigation = useNavigation();

    return (
        <>
            <Spinner />
            {navigation.state !== 'loading' && <Navigate to={`/${redirect}`} />}
        </>
    )
}

export const loginLoader = async () => {
    try {
        const queryParams = new URLSearchParams(window.location.search);

        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/v0/user?${queryParams}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            },
            credentials: 'include'
            
        });


        if (response.ok) {
            // await response.json();
            return 'recipe';
        } else {
            return ''
        }
    } catch (error) {
        throw new Error('Error logging in: ' + error);
    }

};